import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

export const AccountManager = styled("div")`
  width: 596px;

  ${mobile} {
    width: calc(100vw - 2 * var(--sp-8));
  }

  .section {
    ${mobile} {
      flex-direction: column;

      div.button-group {
        button {
          width: 50%;
        }
      }

      div.button {
        width: fit-content;
      }
    }
  }

  .flex.row {
    flex-direction: row;
  }

  .fit-content {
    width: fit-content;
  }

  .card-header--controls svg {
    color: var(--shade1) !important;
  }

  .seperator {
    width: 1px;
    background-color: var(--shade6);
  }

  .close-icon {
    width: var(--sp-4_5);
    height: var(--sp-4_5);
    cursor: pointer;
  }

  .logo-container {
    display: flex;
    width: 44px;
    height: 44px;

    padding: 11px;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background: #d13639;

    svg {
      width: 22px;
      height: 22px;
    }

    ${mobile} {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .confirm-popup-wrapper {
    position: relative;
  }

  .confirm-popup {
    position: absolute;
    width: 242px;
    bottom: calc(var(--sp-6) * 4);
    right: calc(var(--sp-1_5) * -1);
    z-index: 1;

    padding: var(--sp-4);

    background-color: var(--shade10);
    border-radius: var(--br-lg);

    ${mobile} {
      bottom: calc(var(--sp-6) * 2);
      right: unset;
    }
  }
`;

export const BackgroundImage = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-position: 50%;
  background-size: cover;
  box-shadow: inset 0px 1px 0px rgba(227, 229, 234, 0.25);
  top: 0;
  left: 0;
`;

export const ContentWrapper = styled("div")`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const AccountTile = styled("div")`
  display: flex;

  padding: var(--sp-5);

  div:nth-child(-n + 2) {
    border-radius: var(--br-lg);
  }

  .icon {
    width: var(--sp-11);
    height: var(--sp-11);

    border-radius: 50%;
  }

  position: relative;
  width: 100%;
  transition: background-color var(--transition);
  .gradient {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        hsla(var(--shade8-hsl) / 0) 0%,
        hsla(var(--shade8-hsl) / 0.75) 100%
      ),
      radial-gradient(
        50% 100% at 50% 0%,
        hsla(var(--shade8-hsl) / 0) 0%,
        hsla(var(--shade8-hsl) / 0.5) 100%
      );
  }
`;

export const Divider = styled("div")`
  height: 1px;
  width: 100%;
  background-color: var(--shade6);
  margin: var(--sp-6) 0;
`;
